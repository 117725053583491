import type { Host } from '../host';
import { useInternalHost } from '../internal';

export function useHost<T = unknown>(): Host & T {
  return useInternalHost();
}

export function useContext<T>(contextId: string): T {
  return useHost().consumeContext<T>(contextId);
}
