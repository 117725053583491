import { pathToRegexp } from 'path-to-regexp';

import type { ManifestMap } from '@xing-com/crate-core-assets';

export const CATCH_ALL_ROUTE = '/(.*)';

export type RouteInfo = { route: string; owner: string };

function sanitizeRoute(route: string): string {
  route = route.replace(/^site:\/\/[^/]+/, '');

  if (!route.startsWith('/')) route = `/${route}`;
  if (route.endsWith('/*')) route = route.replace(/\/\*$/, CATCH_ALL_ROUTE);

  return route;
}

export function extractRoutes(manifestMap: ManifestMap): RouteInfo[] {
  const routes = Object.values(manifestMap).flatMap((manifest) => {
    const { contributes = {}, owner = 'unknown' } = manifest.metadata;
    const { route, routes } = contributes;

    return [...(route ? [route] : []), ...(routes ?? [])].map(
      (route): RouteInfo => {
        return { route: sanitizeRoute(route), owner };
      }
    );
  });

  // NOTE: This is probably not enough but it's pretty good as the routes will
  // be sorted like this:
  //   "/xingternal/terms/:slug",
  //   "/xingternal/terms",
  //   ...
  //   "/people/(.*)?",
  //   "/people",
  //   ...
  //   "/support/contact/success",
  //   "/support/contact/:topic/:subject",
  //   "/support/contact/:topic",
  //   ...
  //   "/activity",
  //   "/(.*)",
  //   "/"
  // We will then iterate over all routes until we find the first matching.
  return routes.sort((a, b) => {
    return b.route.localeCompare(a.route);
  });
}

export type RouteMatcher = (url: string) => RouteInfo;

export function createRouteMatcher(manifestMap: ManifestMap): RouteMatcher {
  const routes = extractRoutes(manifestMap).map(({ route, owner }) => {
    return {
      route,
      owner,
      regex: pathToRegexp(route),
    };
  });

  return (url) => {
    const [path] = url.split(/[?#]/, 1);
    const entry = routes.find(({ regex }) => regex.test(path));
    const { route = CATCH_ALL_ROUTE, owner = 'unknown' } = entry ?? {};

    return { route, owner };
  };
}
