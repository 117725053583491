import type { FetchFunction } from '../types';

const ALTERING_METHODS = ['POST', 'PUT', 'PATCH', 'DELETE'];
const COOKIE_REGEXP = /(?:^|;\s*)xing_csrf_token=([^;]+)/;
const HTTP_HEADER_KEY = 'X-CSRF-Token';

const currentOrigin = window.location.origin;
const trustedOrigins = /^www\.xing\.com$/;

export function methodRequiresCsrfToken(method = ''): boolean {
  return ALTERING_METHODS.includes(method.toUpperCase());
}

export function isTrustedOrigin(url: string): boolean {
  const { origin } = new URL(url);

  return origin === currentOrigin || trustedOrigins.test(origin);
}

export function getCsrfCookie(): string {
  return document.cookie.match(COOKIE_REGEXP)?.[1] ?? '';
}

export function addCsrfHeader(
  ...args: ConstructorParameters<typeof Request>
): Request {
  const request = new Request(...args);
  const csrfCookie = getCsrfCookie();
  const headerIsRequired =
    isTrustedOrigin(request.url) && methodRequiresCsrfToken(request.method);

  if (headerIsRequired && !request.headers.has(HTTP_HEADER_KEY)) {
    request.headers.set(HTTP_HEADER_KEY, csrfCookie);
  }

  return request;
}

export function configureFetchWithCsrfHeader(
  fetch: FetchFunction
): FetchFunction {
  return (...args) => {
    return fetch(addCsrfHeader(...args));
  };
}
