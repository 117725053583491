import { useEffect, type FC, type ReactElement } from 'react';
import { StyleSheetManager, type ServerStyleSheet } from 'styled-components';

import type { InternalHost } from '@xing-com/crate-xinglet/internal';
import { HostProvider } from '@xing-com/crate-xinglet/internal';

const LoadingStateHandler: FC<{ host: InternalHost }> = ({ host }) => {
  useEffect(() => {
    (function waitForXinglets() {
      const loadingXinglets = [...host.runtime.xingletCache.values()].filter(
        (eventual) => !eventual.settled
      );

      if (loadingXinglets.length) {
        Promise.all(loadingXinglets).then(waitForXinglets, waitForXinglets);
      } else {
        document
          .querySelector('#app[data-mountpoint]')
          ?.setAttribute('data-xinglets-settled', '');
      }
    })();
  });

  return <></>;
};

export function createEntryPoint(
  host: InternalHost,
  name: string,
  { sheet, ...props }: { sheet?: ServerStyleSheet; [key: string]: unknown } = {}
): ReactElement {
  return (
    <StyleSheetManager sheet={sheet?.instance}>
      <HostProvider host={host}>
        <LoadingStateHandler host={host} />
        <host.XingletLoader
          {...props}
          host={host}
          name={name}
          fallback={<span></span>}
        />
      </HostProvider>
    </StyleSheetManager>
  );
}
