import type { FC } from 'react';

import { useHost } from './hooks/use-host';
import type { XingletLoaderProps } from './xinglet';

export const XingletLoader: FC<XingletLoaderProps> = (props) => {
  const host = useHost();

  return <host.XingletLoader {...props} />;
};
