import ReactDOM from 'react-dom';

import type { ManifestMap } from '@xing-com/crate-core-assets';
import { createEntryPoint } from '@xing-com/crate-runtime';
import type { ExceptionCapturer } from '@xing-com/crate-xinglet';
import type {
  GlobalScope,
  InternalBrowserHost,
  RuntimeConfig,
} from '@xing-com/crate-xinglet/internal';

import { createBrowserHost } from './create-browser-host';

declare const globalThis: GlobalScope & {
  crateHost: InternalBrowserHost;
  document: typeof document;
};

export async function render({
  captureException,
  config,
  manifestMap,
  startXinglets = [],
  serverData = {},
}: {
  captureException: ExceptionCapturer;
  config: RuntimeConfig;
  manifestMap: ManifestMap;
  startXinglets: Array<string>;
  serverData: Record<string, unknown>;
}): Promise<void> {
  const { basePath, enableMocks = false, entryPoint, manifestId } = config;

  const host = createBrowserHost(
    manifestMap,
    config,
    serverData,
    captureException
  );
  const { runtime } = host;

  globalThis.crateImportChunk = host.importModule.bind(host);
  globalThis.crateHost = host;

  if (enableMocks) {
    // eslint-disable-next-line node/no-unsupported-features/es-syntax
    const { setupMocking } = await import('./mocks');
    await setupMocking(config.mockSessionId);
  }

  // [SSR] if we have startXinglets to load, we do trigger this
  // this is required, since react needs to have all code available to
  // hydrate the server DOM
  await Promise.all(
    [entryPoint, ...startXinglets].map(async (name) => {
      await runtime.loadXingletComponent(host, name);
    })
  );

  const mountpoint = globalThis.document.querySelector('#app[data-mountpoint]');
  if (!mountpoint) {
    throw new Error('No mountpoint found');
  }

  const loader = (
    <div dangerouslySetInnerHTML={{ __html: mountpoint.innerHTML }} />
  );
  const app = createEntryPoint(host, entryPoint, {
    basePath,
    fallback: loader,
  });

  // eslint-disable-next-line react/no-deprecated
  ReactDOM.hydrate(app, mountpoint);
  mountpoint.setAttribute('data-mounted', '');

  if (manifestId === 'dev') {
    const { startDevMode } = await import('./dev-mode');

    startDevMode(host);
  }
}
