/* eslint-disable */
/**
 * This file was automatically generated. DO NOT MODIFY IT BY HAND.
 * Instead, modify the "sharedDependencies" in the root package.json,
 * then run `yarn monorepo code-generator` to regenerate this file.
 */
import type { GlobalScope } from '@xing-com/crate-xinglet/internal';
import { register } from './register';

import * as import1 from /* webpackChunkName: "@reach/router" */ '@reach/router';
import * as import2 from /* webpackChunkName: "@xing-com/crate-core-assets" */ '@xing-com/crate-core-assets';
import * as import3 from /* webpackChunkName: "@xing-com/crate-core-fetch" */ '@xing-com/crate-core-fetch';
import * as import4 from /* webpackChunkName: "@xing-com/crate-xinglet" */ '@xing-com/crate-xinglet';
import * as import5 from /* webpackChunkName: "@xing-com/crate-xinglet/internal" */ '@xing-com/crate-xinglet/internal';
import * as import6 from /* webpackChunkName: "react-dom" */ 'react-dom';
import * as import7 from /* webpackChunkName: "react-helmet-async" */ 'react-helmet-async';
import * as import8 from /* webpackChunkName: "react/jsx-runtime" */ 'react/jsx-runtime';
import * as import9 from /* webpackChunkName: "react" */ 'react';
import * as import10 from /* webpackChunkName: "styled-components" */ 'styled-components';
import * as import11 from /* webpackChunkName: "tslib" */ 'tslib';

// prettier-ignore
export function registerLibraries(scope: GlobalScope): void {
  register(scope, '@reach/router', import1);
  register(scope, '@xing-com/crate-core-assets', import2);
  register(scope, '@xing-com/crate-core-fetch', import3);
  register(scope, '@xing-com/crate-xinglet', import4);
  register(scope, '@xing-com/crate-xinglet/internal', import5);
  register(scope, 'react-dom', import6);
  register(scope, 'react-helmet-async', import7);
  register(scope, 'react/jsx-runtime', import8);
  register(scope, 'react', import9);
  register(scope, 'styled-components', import10);
  register(scope, 'tslib', import11);
  register(scope, '@apollo/client', () => import(/* webpackChunkName: "@apollo/client" */ '@apollo/client'));
  register(scope, '@formatjs/icu-messageformat-parser', () => import(/* webpackChunkName: "@formatjs/icu-messageformat-parser" */ '@formatjs/icu-messageformat-parser'));
  register(scope, '@mdx-js/react', () => import(/* webpackChunkName: "@mdx-js/react" */ '@mdx-js/react'));
  register(scope, '@xing-com/crate-communication-in-view', () => import(/* webpackChunkName: "@xing-com/crate-communication-in-view" */ '@xing-com/crate-communication-in-view'));
  register(scope, '@xing-com/crate-communication-tracking', () => import(/* webpackChunkName: "@xing-com/crate-communication-tracking" */ '@xing-com/crate-communication-tracking'));
  register(scope, '@xing-com/crate-demo-global-package', () => import(/* webpackChunkName: "@xing-com/crate-demo-global-package" */ '@xing-com/crate-demo-global-package'));
  register(scope, '@xing-com/crate-insights-shared-dashboard-list-var', () => import(/* webpackChunkName: "@xing-com/crate-insights-shared-dashboard-list-var" */ '@xing-com/crate-insights-shared-dashboard-list-var'));
  register(scope, '@xing-com/crate-layout-scroll-memo-module', () => import(/* webpackChunkName: "@xing-com/crate-layout-scroll-memo-module" */ '@xing-com/crate-layout-scroll-memo-module'));
  register(scope, '@xing-com/crate-lebenslauf-shared-client-document-variable', () => import(/* webpackChunkName: "@xing-com/crate-lebenslauf-shared-client-document-variable" */ '@xing-com/crate-lebenslauf-shared-client-document-variable'));
  register(scope, '@xing-com/crate-stream-global-contexts', () => import(/* webpackChunkName: "@xing-com/crate-stream-global-contexts" */ '@xing-com/crate-stream-global-contexts'));
  register(scope, '@xing-com/frame-data-provider', () => import(/* webpackChunkName: "@xing-com/frame-data-provider" */ '@xing-com/frame-data-provider'));
  register(scope, '@xing-com/hub', () => import(/* webpackChunkName: "@xing-com/hub" */ '@xing-com/hub'));
  register(scope, '@xing-com/ticktricktrack', () => import(/* webpackChunkName: "@xing-com/ticktricktrack" */ '@xing-com/ticktricktrack'));
  register(scope, 'cross-fetch', () => import(/* webpackChunkName: "cross-fetch" */ 'cross-fetch'));
  register(scope, 'graphql', () => import(/* webpackChunkName: "graphql" */ 'graphql'));
  register(scope, 'react-intl', () => import(/* webpackChunkName: "react-intl" */ 'react-intl'));
  register(scope, 'react-router-dom', () => import(/* webpackChunkName: "react-router-dom" */ 'react-router-dom'));
  register(scope, 'react-router', () => import(/* webpackChunkName: "react-router" */ 'react-router'));
}
