import * as React from 'react';

type LoadFunction = () => Promise<Record<string, unknown>>;
type ResolveFunction = (module: Record<string, unknown>) => React.ComponentType;

export function ImportComponent({
  load,
  resolve,
  ...props
}: { load: LoadFunction; resolve: ResolveFunction } & Record<
  string,
  unknown
>): JSX.Element {
  const [{ Component, error }, setState] = React.useState<{
    Component?: React.ComponentType;
    error?: undefined;
  }>({});

  React.useEffect(() => {
    let isMounted = true;

    if (Component || error) return;

    load().then(
      (module) => {
        if (!isMounted) return;

        setState({ Component: resolve(module) });
      },
      (error) => {
        if (!isMounted) return;

        setState({ error });
      }
    );

    return () => {
      isMounted = false;
    };
  }, [Component, error, load, resolve]);

  if (!Component || error) {
    return <></>;
  }

  return <Component {...props} />;
}

/** @deprecated Pease use a xinglet instead */
export function importComponent(
  load: LoadFunction,
  resolve: ResolveFunction = (module) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return module.default as React.ComponentType;
  }
): React.ComponentType {
  return (props: Record<string, unknown>) => {
    return ImportComponent({ ...props, load, resolve });
  };
}
