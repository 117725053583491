import { joinUrl } from '@xing-com/crate-core-assets';

const trackingKeys = [
  '_source',
  'ace',
  'bce',
  'bci',
  'bpe',
  'brt',
  'cce',
  'cci',
  'csy',
  'gclid',
  'gp',
  'ijt',
  'jl_tid',
  'msclkid',
  'rac',
  'rce',
  'rci',
  'reagent',
  'sc_cmp',
  'sc_fbadid',
  'sc_gry',
  'sc_o',
  'sc_oge',
  'sc_ogi',
  'sc_p',
  'sc_pce',
  'sc_wae',
  'sc_wce',
  'ttt_webview',
  'utm_source',
  'yac',
  'yce',
  'yci',
];

export function buildRedirectUrl(
  loginAppUrl: string | URL,
  destination: string | URL,
  path = '/',
  locale?: string
): string {
  const redirectTarget = new URL(joinUrl(new URL(loginAppUrl).href, path));
  const destUrl = new URL(destination);

  if (locale) {
    redirectTarget.searchParams.append('locale', locale);
  }

  trackingKeys.forEach((key) => {
    destUrl.searchParams.getAll(key).forEach((value) => {
      redirectTarget.searchParams.append(key, value);
    });

    destUrl.searchParams.delete(key);
  });

  redirectTarget.searchParams.append('dest_url', destUrl.href);

  return redirectTarget.href;
}
