import type { ComponentType } from 'react';

import type {
  ExceptionCapturer,
  ExecuteCommandFunction,
  Metadata,
  XingletLoaderProps,
} from '@xing-com/crate-xinglet';
import { HostProvider } from '@xing-com/crate-xinglet/internal';
import type {
  InternalCommonHost,
  InternalHost,
  Runtime,
  RuntimeConfig,
} from '@xing-com/crate-xinglet/internal';

import { createContextHelper } from './context-helper';
import { InternalXingletLoader } from './xinglet-loader';

export interface CommonHostOptions {
  captureException: ExceptionCapturer;
  executeCommand?: ExecuteCommandFunction;
  fetch: typeof globalThis.fetch;
  hostname: string;
  isPreview: boolean;
  redirectToLogin(path?: string): never;
  XingletLoader?: ComponentType<XingletLoaderProps>;
}

export function createCommonHost(
  runtime: Runtime,
  config: RuntimeConfig,
  {
    captureException,
    executeCommand = () => {
      throw new Error('Not available in this context');
    },
    fetch,
    hostname,
    // isPreview,
    redirectToLogin,
    XingletLoader,
  }: CommonHostOptions
): InternalCommonHost {
  const { isPreview = false } = config;
  const { ContextProvider, consumeContext, provideContext } =
    createContextHelper();

  const commonHost: InternalCommonHost = {
    captureException,
    config,
    consumeContext,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    executeCommand: executeCommand as () => never,
    fetch,
    getHostname: (subdomain?: string) => {
      if (!subdomain || hostname === 'localhost') {
        return hostname;
      }
      const hostWithoutSubdomain = hostname.replace(/^www\./, '');
      return `${subdomain}.${hostWithoutSubdomain}`;
    },
    getXingletMetadata: (): Metadata[] => {
      return Object.values(commonHost.runtime.metadataMap);
    },
    importModule: () => {
      throw new Error(
        'Can not import module without specific host implementation for it'
      );
    },
    importXinglet: () => {
      throw new Error(
        'Can not import xinglet without specific host implementation for it'
      );
    },
    isPreview,
    provideContext(xingletName: string) {
      return provideContext(commonHost, xingletName);
    },
    redirectToLogin,
    runtime,
    XingletLoader: (props: XingletLoaderProps) => {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const host = commonHost as InternalHost;

      XingletLoader ??= (props) => {
        return InternalXingletLoader({ ...props, host });
      };

      return (
        <HostProvider host={host}>
          <ContextProvider name={props.name} host={host}>
            <XingletLoader {...props} />
          </ContextProvider>
        </HostProvider>
      );
    },
  };

  return commonHost;
}
