import type { ReportHandler } from 'web-vitals';
import { getCLS, getFID, getLCP } from 'web-vitals';

import {
  ensureLeadingSlash,
  trimTrailingSlash,
} from '@xing-com/crate-core-assets';
import type { RouteMatcher } from '@xing-com/crate-runtime';
import type { BrowserBootstrapConfig } from '@xing-com/crate-xinglet/internal';

import { fetch } from './index';

type TrackParams = { v: '1'; [key: string]: string };

export function getLogjamAction(
  matchRoute: RouteMatcher,
  basePath: string,
  rawPathname: string
): string {
  const pathname = trimTrailingSlash(ensureLeadingSlash(rawPathname));

  if (pathname === basePath) {
    return 'index';
  }

  const { route } = matchRoute(pathname);

  return route.replace(/^\//, '').replace(/:/g, '$').replace(/\//g, '::');
}

export function shouldBeTracked(response: Response): boolean {
  return response.headers.has('X-Logjam-Request-Id');
}

function buildTrackParams(
  id: string,
  action: string,
  location: string,
  timings: number[],
  params: Partial<TrackParams> = {}
): TrackParams {
  return {
    v: '1',
    logjam_request_id: id,
    logjam_action: action,
    url: location,
    rts: timings.join(','),
    ...params,
    _: Date.now().toString(),
  };
}

export function buildLoadTimingsParams(
  id: string,
  action: string,
  params: Partial<TrackParams> = {}
): TrackParams {
  const { document, performance, screen } = window;
  const { navigation = { redirectCount: 0 }, timing = {} } = performance || {};

  const timings = [
    'navigationStart',
    'fetchStart',
    'domainLookupStart',
    'domainLookupEnd',
    'connectStart',
    'connectEnd',
    'requestStart',
    'responseStart',
    'responseEnd',
    'domLoading',
    'domInteractive',
    'domContentLoadedEventStart',
    'domContentLoadedEventEnd',
    'domComplete',
    'loadEventStart',
    'loadEventEnd',
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
  ].reduce((list, key) => list.concat((timing as any)[key] || 0), []);

  const query = document.location.pathname;

  return buildTrackParams(id, action, query, timings, {
    ...params,
    screen_height: screen.height.toString(),
    screen_width: screen.width.toString(),
    html_nodes: document.getElementsByTagName('*').length.toString(),
    script_nodes: document.scripts.length.toString(),
    style_nodes: document.styleSheets.length.toString(),
    redirect_count: (navigation.redirectCount || 0).toString(),
  });
}

function buildFetchTimingParams(
  response: Response,
  start: number,
  end: number,
  params: Partial<TrackParams> = {}
): TrackParams {
  const { url, headers } = response;

  const id = headers.get('X-Logjam-Request-Id');
  const action =
    headers.get('X-Logjam-Request-Action') || headers.get('X-Logjam-Action');

  const { pathname } = Object.assign(document.createElement('a'), {
    href: url,
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return buildTrackParams(id!, action!, pathname, [start, end], params);
}

function track(endpoint: string, params: Record<string, string>): string {
  const url = `${endpoint}?${new URLSearchParams(params)}`;

  new Image().src = url;

  return url;
}

export function trackLoadTimings(
  endpoint: string,
  ...args: Parameters<typeof buildLoadTimingsParams>
): string {
  return track(endpoint, buildLoadTimingsParams(...args));
}

export function trackFetchTiming(
  endpoint: string,
  ...args: Parameters<typeof buildFetchTimingParams>
): string {
  return track(endpoint, buildFetchTimingParams(...args));
}

export function registerLogjamTracking(
  matchRoute: RouteMatcher,
  runtimeConfig: BrowserBootstrapConfig
): void {
  const { basePath, logjam: { action, requestId } = {} } = runtimeConfig;

  if (!action || !requestId) {
    return;
  }

  const logjamBaseAction = getLogjamAction(
    matchRoute,
    basePath,
    window.location.pathname
  );

  if (!logjamBaseAction) {
    return;
  }

  const track = (): void => {
    setTimeout(() => {
      trackLoadTimings('/logjam/page', requestId, action);
    }, 0);
  };

  if (document.readyState === 'complete') {
    track();
  } else {
    window.addEventListener('load', track, false);
  }

  const handler: ReportHandler = ({ id, name, value }) => {
    const action = `${logjamBaseAction}#get`;
    const metric = encodeURIComponent(name.toLowerCase());
    const payload = [
      `logjam_request_id=${encodeURIComponent(requestId)}`,
      `logjam_action=${encodeURIComponent(action)}`,
      `metrics[].id=${encodeURIComponent(id)}`,
      `metrics[].${metric}=${encodeURIComponent(value)}`,
    ].join('&');

    fetch(`/logjam/webvitals?${payload}`, {
      method: 'POST',
      mode: 'no-cors',
      keepalive: true,
    });
  };

  getCLS(handler);
  getFID(handler);
  getLCP(handler);
}
