import { Eventual } from '@xing-com/crate-xinglet';
import type { Xinglet, XingletClass } from '@xing-com/crate-xinglet';
import type { InternalHost } from '@xing-com/crate-xinglet/internal';

export function createXinglet(
  host: InternalHost,
  name: string,
  xingletClass: XingletClass
): Eventual<Xinglet> {
  const xinglet: Xinglet = Object.assign(new xingletClass(host), {
    name,
  });

  xinglet.createContexts?.(host.provideContext(name));

  if (host.isServer) {
    return Eventual.resolve(xinglet);
  } else {
    return Eventual.resolve(
      (async () => {
        const { xingletCache } = host.runtime;

        await Promise.all([
          ...Array.from(xingletCache.values()).map(async ({ value }) => {
            if (value === xinglet) return;
            return value?.onNewXinglet?.(name);
          }),
          ...Array.from(xingletCache.keys()).map(async (otherName) => {
            if (name === otherName) return;
            return xinglet.onNewXinglet?.(otherName);
          }),
        ]);

        await host.registerMocks?.(name, xinglet);

        return xinglet;
      })()
    );
  }
}
