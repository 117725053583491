import { isRedirect } from '@reach/router';
import * as React from 'react';

import { captureException, useHost } from '@xing-com/crate-xinglet';

export interface XingletErrorBoundaryProps {
  error?: React.ReactElement;
  name: string;
}

class InternalErrorBoundary extends React.Component<
  React.PropsWithChildren<{
    fallback: React.ComponentType<{ error: Error }>;
    name: string;
  }>,
  { error: Error }
> {
  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    if (isRedirect(error)) {
      throw error;
    }

    this.setState({ error });

    const renderingError = new Error(error.message);
    renderingError.name = 'ReactRenderingError';
    renderingError.stack = info.componentStack || undefined;
    renderingError.cause = error;

    captureException(renderingError, { xinglet: this.props.name });
  }

  render(): JSX.Element {
    const { children, fallback: Fallback } = this.props;
    const { error } = this.state ?? {};

    return error ? <Fallback error={error} /> : <>{children}</>;
  }
}

export const XingletErrorBoundary: React.FC<
  React.PropsWithChildren<XingletErrorBoundaryProps>
> = ({ name, children, error: errorComponent }) => {
  const host = useHost();

  const fallback: React.FC<{ error: Error }> = ({ error }) => {
    if (errorComponent) {
      return errorComponent;
    } else if (host.isPreview) {
      return (
        <div style={{ color: 'red', fontFamily: 'monospace', padding: '40px' }}>
          <strong>Failed to load xinglet &quot;{name}&quot;:</strong>
          <br />
          {error.message}
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <InternalErrorBoundary name={name} fallback={fallback}>
      {children}
    </InternalErrorBoundary>
  );
};
