import type { FC, PropsWithChildren } from 'react';
import { createContext } from 'react';

import type { InternalHost } from './runtime-host';

export type HostContext = {
  host: InternalHost;
};

export const context = createContext<HostContext | undefined>(undefined);
context.displayName = 'HostContext';

export const HostProvider: FC<PropsWithChildren<HostContext>> = ({
  children,
  host,
}) => {
  return <context.Provider value={{ host }}>{children}</context.Provider>;
};
