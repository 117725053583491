export interface ServerRequest {
  url: URL;
  method: string;
  headers: Record<string, string | string[] | undefined>;
  cookies: Record<string, string | undefined>;
  getHeader(key: string): string | undefined;
}

export function createServerRequest(
  url: URL | string,
  {
    method = 'GET',
    headers = {},
    cookies = {},
  }: {
    method?: string;
    headers?: Record<string, string | string[] | undefined>;
    cookies?: Record<string, string | undefined>;
  } = {}
): ServerRequest {
  return {
    url: new URL(url),
    method,
    headers,
    cookies,
    getHeader(key) {
      const header = headers[key];

      if (Array.isArray(header)) {
        throw new Error(`Header '${key}' exists multiple times`);
      }

      return header;
    },
  };
}
