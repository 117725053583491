import type { Xinglet } from '@xing-com/crate-xinglet';
import type { InternalHost } from '@xing-com/crate-xinglet/internal';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const internalExecuteCommand = (
  host: InternalHost,
  commandName: string,
  ...args: any[]
): Promise<any | void> | any | void => {
  const commands = Object.entries(host.runtime.metadataMap).filter(
    ([, metadata]) =>
      metadata.contributes?.commands?.some(
        (command) => command.name === commandName
      )
  );

  if (commands.length === 0) {
    throw new Error(
      `Command '${commandName}' not found (is the command declared in the xinglet.json?)`
    );
  }
  if (commands.length > 1) {
    throw new Error(
      `Command '${commandName}' is ambiguous (provided by '${commands
        .map(([name]) => name)
        .join("', ")}')`
    );
  }

  const xingletName = commands[0][0];

  const execute = (xinglet: Xinglet): any => {
    if (typeof xinglet !== 'object') {
      throw new Error(
        `Xinglet '${xingletName}' need to be an implementation of Xinglet`
      );
    }
    if (typeof (xinglet as any)[commandName] !== 'function') {
      throw new Error(
        `Command '${commandName}' of xinglet '${xingletName}' is required to be a function`
      );
    }
    return (xinglet as any)[commandName](...args);
  };

  const eventualXinglet = host.runtime.loadXinglet(host, xingletName);

  if (eventualXinglet.value) {
    return execute(eventualXinglet.value);
  } else {
    return Promise.resolve(eventualXinglet.then(execute));
  }
};
/* eslint-enable @typescript-eslint/no-explicit-any */
