import { useRuntimeConfig } from '../internal';

type DeviceInfo = {
  isWebview: boolean;
  isAndroid: boolean;
  mobileProtocol?: string;
  profileUrlMobile?: string;
};

export function useDeviceInfo(): DeviceInfo {
  const {
    deviceInfo: { isAndroid = false, mobileProtocol, profileUrlMobile } = {},
    isWebview = false,
  } = useRuntimeConfig();

  return {
    isWebview,
    isAndroid,
    mobileProtocol,
    profileUrlMobile,
  };
}
