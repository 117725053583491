import type { FetchFunction } from '../types';
import { shouldBeTracked, trackFetchTiming } from './logjam';

export function configureFetchWithLogjamTracking(
  fetch: FetchFunction
): FetchFunction {
  return (...fetchArgs) => {
    const startTime = Date.now();

    return fetch(...fetchArgs).then((response) => {
      const endTime = Date.now();

      if (shouldBeTracked(response)) {
        trackFetchTiming('/logjam/ajax', response, startTime, endTime);
      }

      return response;
    });
  };
}
