import type { GlobalScope } from '@xing-com/crate-xinglet/internal';

export function register(
  globalScope: GlobalScope,
  dependency: string,
  value: Record<string, unknown> | (() => Promise<Record<string, unknown>>)
): void {
  (globalScope.crateImports ??= {})[dependency] =
    typeof value === 'function' ? () => value() : value;
}
