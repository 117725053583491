import type { GlobalScope } from '@xing-com/crate-xinglet/internal';

declare const globalThis: GlobalScope;

//
// this is a 'magic' webpack variable to define a dynamic public path
// we use it to switch between local development, preview and public
// without requiring different bundles
//
declare let __webpack_public_path__: string;
// eslint-disable-next-line prefer-const, @typescript-eslint/no-unused-vars
__webpack_public_path__ = `${globalThis.crate.assetsBaseUrl}/`;
