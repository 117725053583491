import devNote from '@xing-com/dev-note';

import { useHost } from './use-host';

/**
 * Some common HTTP status codes that can be set on the response.
 */
export const statusCodes = {
  unauthorized: 401,
  notFound: 404,
  gone: 410,
  internalServerError: 500,
} as const;

type UseStatusCodeOptions = {
  /**
   * If true, the status code will be set. Otherwise nothing will happen.
   * This is useful because hooks cannot be conditionally called.
   * @default true
   */
  isActive?: boolean;
};

/**
 * Set the status code of the response, often for SEO. The last component to
 * call this hook "wins" and sets the status code if there are multiple calls,
 * so probably make sure you only call this hook once. This hook can only be
 * called on the server.
 *
 * @param code The HTTP status code to set, e.g. 404, use the `statusCodes` object for common codes.
 * @param options Options to control the behavior of the hook. The `isActive` option can be used to conditionally set the status code because you can not conditionally call hooks.
 * @returns Nothing.
 */
export const useStatusCode = (
  code: number,
  { isActive = true }: UseStatusCodeOptions = {}
): void => {
  const host = useHost();

  if (!isActive) return;

  if (!host.isServer) {
    devNote.warn('Status code can only be set on the server');
    return;
  }

  if (code < 100 || code >= 600) {
    devNote.error(
      `Invalid status code ${code}, using ${statusCodes.internalServerError} instead`
    );
    code = statusCodes.internalServerError;
  }

  host.setResponseStatusCode(code);
};

export const useNotFoundStatusCode = (options?: UseStatusCodeOptions): void => {
  useStatusCode(statusCodes.notFound, options);
};

export const useGoneStatusCode = (options?: UseStatusCodeOptions): void => {
  useStatusCode(statusCodes.gone, options);
};
