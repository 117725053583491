import { redirectTo } from '@reach/router';
import { createElement } from 'react';

import type { ManifestMap } from '@xing-com/crate-core-assets';
import { configureFetch } from '@xing-com/crate-core-fetch/src/browser';
import {
  buildRedirectUrl,
  createCommonHost,
  createRuntime,
} from '@xing-com/crate-runtime';
import type { ExceptionCapturer, Xinglet } from '@xing-com/crate-xinglet';
import type {
  InternalBrowserHost,
  RuntimeConfig,
} from '@xing-com/crate-xinglet/internal';

import { internalExecuteCommand } from './execute-command';
import { importModule } from './import-module';
import { importXinglet } from './import-xinglet';
import { BrowserXingletLoader } from './xinglet-loader';

export function createBrowserHost(
  manifestMap: ManifestMap,
  config: RuntimeConfig,
  serverData: Record<string, unknown> = {},
  captureException: ExceptionCapturer = () => undefined
): InternalBrowserHost {
  const { enableMocks = false, isPreview = false, loginAppUrl } = config;

  const runtime = createRuntime(manifestMap, { autoRerender: true });
  const host: InternalBrowserHost = Object.assign(
    createCommonHost(runtime, config, {
      captureException,
      executeCommand: (commandName, ...args) => {
        return internalExecuteCommand(host, commandName, ...args);
      },
      fetch: configureFetch(config),
      hostname: document.location.hostname,
      isPreview,
      redirectToLogin: (flow) => {
        redirectTo(
          buildRedirectUrl(loginAppUrl, globalThis.location.href, flow)
        );
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return undefined as never;
      },
      XingletLoader: (props) => {
        return createElement(BrowserXingletLoader, { ...props, host });
      },
    }),
    {
      isServer: false as const,
      config,
      importModule: <T>(name: string, entry?: string) => {
        return importModule<T>(host, name, entry);
      },
      importXinglet: (name: string) => {
        return importXinglet(host, name);
      },
      loadServerData(key: string) {
        return serverData[key];
      },
      registerMocks: enableMocks
        ? async (xingletName: string, xinglet: Xinglet): Promise<void> => {
            const { registerMocks } = await import('./mocks');

            await registerMocks(host, xingletName, xinglet);
          }
        : undefined,
    }
  );

  return host;
}
