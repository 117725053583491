import { useContext } from 'react';

import { context } from './runtime-context';
import type { InternalHost } from './runtime-host';

export function useInternalHost(): InternalHost {
  const host = useContext(context)?.host;

  if (!host) {
    throw new Error('No host context found. Please setup the HostProvider');
  }

  return host;
}
