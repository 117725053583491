import type { InternalBrowserHost } from './internal';

export type ExceptionCapturer = (
  error: Error | string,
  context?: Record<string, unknown> | undefined
) => void;

declare const globalThis: {
  crateHost: InternalBrowserHost;
};

export const captureException: ExceptionCapturer = (error, context) => {
  globalThis.crateHost?.captureException(error, context);
};
