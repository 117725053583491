export function trimLeadingSlash(part: string): string {
  return part.replace(/^\/+/, '');
}

export function trimTrailingSlash(part: string): string {
  return part.replace(/\/+$/, '');
}

export function ensureLeadingSlash(part: string): string {
  return `/${trimLeadingSlash(part)}`;
}

export function joinUrl(...parts: string[]): string {
  if (parts.length === 0) return '';

  const last = parts.length - 1;

  return parts
    .map((part, i) => {
      const isFirst = i === 0;
      const isLast = i === last;

      if (!isFirst) part = trimLeadingSlash(part);
      if (!isLast) part = trimTrailingSlash(part);

      return part;
    })
    .join('/');
}
