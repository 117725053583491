import type {
  CrateImports,
  CrateModule,
} from '@xing-com/crate-xinglet/internal';

async function sleep(ms: number): Promise<void> {
  await new Promise((resolve) => setTimeout(resolve, ms));
}

export async function loadLibraries(
  crateImports: CrateImports,
  libraries: string[]
): Promise<CrateModule[]> {
  return await Promise.all(
    libraries.map(async (library) => {
      const entry = crateImports[library];

      if (!entry) {
        throw new Error(`State error: '${library} is not a registered library`);
      } else if (typeof entry !== 'function') {
        // NOTE: If it's not a function it means we've already loaded it.
        return entry;
      }

      const result = await entry().catch(async () => {
        // back-off for a short amount and then retry
        await sleep(250);
        return await entry().catch((error: unknown) => {
          throw new Error(
            `Failed to load library '${library}'; Cause: ${
              error instanceof Error ? error.message : error
            }`
          );
        });
      });

      return (crateImports[library] = result);
    })
  );
}
