export function isPromiseLike<T>(value: unknown): value is PromiseLike<T> {
  return (
    !!value &&
    (value instanceof Promise ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
      typeof (value as any).then === 'function')
  );
}

const PENDING = 1 as const;
const RESOLVED = 2 as const;
const REJECTED = 3 as const;

type State = typeof PENDING | typeof RESOLVED | typeof REJECTED;

export class Eventual<T> implements PromiseLike<T> {
  static resolve<T = void>(value: T | PromiseLike<T>): Eventual<T> {
    if (isPromiseLike(value)) {
      return new Eventual<T>((resolve, reject) => {
        value.then(
          (value) => resolve(value),
          (error) => reject(error)
        );
      });
    } else {
      return new Eventual<T>((resolve) => resolve(value));
    }
  }

  static reject<T = never>(error: unknown): Eventual<T> {
    return new Eventual<T>((_, reject) => reject(error));
  }

  #state: State = PENDING;
  #promise?: PromiseLike<
    | { state: typeof RESOLVED; result: T }
    | { state: typeof REJECTED; result: unknown }
  >;

  get settled(): boolean {
    return this.#state !== PENDING;
  }

  #value: T | undefined;
  get value(): T | undefined {
    return this.#value;
  }

  #error: unknown;
  get error(): unknown {
    return this.#error;
  }

  constructor(
    executor: (
      resolve: (value: T) => void,
      reject: (error: unknown) => void
    ) => void
  ) {
    let resolve: (value: T) => void;
    let reject: (error: unknown) => void;

    executor(
      (value) => {
        this.#value = value;
        this.#state = RESOLVED;
        this.#promise = undefined;

        resolve?.(value);
      },
      (error) => {
        this.#error = error;
        this.#state = REJECTED;
        this.#promise = undefined;

        reject?.(error);
      }
    );

    if (this.settled) {
      // NOTE: if we have already settled it means that the executor was
      // synchronous. In that case we don't need to create the promise anymore.
      return;
    }

    this.#promise = new Promise<T>((promiseResolve, promiseReject) => {
      resolve = promiseResolve;
      reject = promiseReject;
    }).then(
      (value) => ({ state: RESOLVED, result: value }),
      (error) => ({ state: REJECTED, result: error })
    );
  }

  then<TResult1 = T, TResult2 = never>(
    onfulfilled:
      | ((value: T) => TResult1 | PromiseLike<TResult1>)
      | undefined
      | null,
    onrejected?:
      | ((reason: unknown) => TResult2 | PromiseLike<TResult2>)
      | undefined
      | null
  ): Eventual<TResult1 | TResult2> {
    const then =
      onfulfilled ||
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ((value) => value as unknown as TResult1);

    try {
      if (this.#state === RESOLVED) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return Eventual.resolve<TResult1 | TResult2>(then(this.value!));
      } else if (this.#state === REJECTED) {
        if (onrejected) {
          return Eventual.resolve<TResult1 | TResult2>(onrejected(this.error));
        } else {
          return Eventual.reject<TResult1 | TResult2>(this.error);
        }
      } else {
        return Eventual.resolve(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.#promise!.then(({ state, result }) => {
            if (state === REJECTED) {
              if (onrejected) {
                return onrejected(result);
              } else {
                throw result ?? 'Eventual empty rejection';
              }
            } else {
              return then(result);
            }
          })
        );
      }
    } catch (error) {
      return Eventual.reject<TResult1 | TResult2>(error);
    }
  }

  catch<TResult = never>(
    onrejected?:
      | ((reason: unknown) => TResult | PromiseLike<TResult>)
      | undefined
      | null
  ): Eventual<T | TResult> {
    return this.then(undefined, onrejected);
  }
}
