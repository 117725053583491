export function createRequest(
  input: RequestInfo | URL,
  init?: RequestInit
): Request {
  if (typeof input === 'string') {
    const { credentials = 'same-origin', ...options } = init || {};

    return new Request(input, { ...options, credentials });
  } else {
    return new Request(input, init);
  }
}
