import { joinUrl } from '@xing-com/crate-core-assets';
import type { Manifest } from '@xing-com/crate-core-assets';
import { loadLibraries } from '@xing-com/crate-runtime-libraries';
import { Eventual } from '@xing-com/crate-xinglet';
import type {
  I18nModule,
  XingletClass,
  XingletModule,
} from '@xing-com/crate-xinglet';
import type {
  GlobalScope,
  InternalHost,
} from '@xing-com/crate-xinglet/internal';

import {
  importModule,
  loadScript,
  loadStyle,
  tryGetModule,
} from './import-module';

declare const globalThis: GlobalScope;

async function importXingletAssets(
  manifest: Manifest,
  assetsBaseUrl: string
): Promise<void> {
  const scripts = manifest.assets
    .filter((asset) => /\.js(\?\d+)?$/.test(asset))
    .map((asset) => loadScript(joinUrl(assetsBaseUrl, asset)));

  const styles = manifest.assets
    .filter((asset) => /\.css(\?\d+)?$/.test(asset))
    .map(async (asset) => {
      try {
        loadStyle(joinUrl(assetsBaseUrl, asset));
      } catch (err) {
        console.error(err);
      }
    });

  await Promise.all([...scripts, ...styles]);
}

export function importXinglet(
  host: InternalHost,
  name: string
): Eventual<XingletClass> {
  return Eventual.resolve(tryGetModule<XingletModule>(name, 'main')).then(
    async (module) => {
      if (!module) {
        const { assetsBaseUrl, language } = host.config;
        const manifest = host.runtime.manifestMap[name];
        const entry = `lang-${language}`;

        if (!manifest) {
          throw new Error(`Could not find manifest for xinglet "${name}"`);
        }

        await loadLibraries(
          globalThis.crateImports,
          manifest.metadata.dependencies
        );
        await importXingletAssets(manifest, assetsBaseUrl);

        if (manifest.chunks[entry]) {
          const { messages } = await importModule<I18nModule>(
            host,
            name,
            entry
          );

          Object.assign(host.runtime.languageData, messages);
        }

        module = await importModule<XingletModule>(host, name);

        if (!module) {
          throw new Error(`Could not load xinglet "${name}"`);
        }
      }

      return module.default;
    }
  );
}
